import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class DropdownLanguage extends Component {
    //static displayName = DropdownLanguage.name;
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'English',
        };
    }

    // Handle dropdown change and reload the page using React Router
    handleChange = (event) => {
        localStorage.setItem('language', event.target.value);
        window.location.reload();
    };

    componentDidMount() {
        // Set a timeout to change the state after 5 seconds
        setTimeout(() => {
            var name = localStorage.getItem('language');
            this.setState({ selectedOption: name });
        }, 2000); // 2000ms = 2 seconds
    }

    render() {
        return (
            <select value={this.state.selectedOption} onChange={this.handleChange} name="Idlanguages" id="Idlanguages" style={{ color: '#e93314', paddingTop: '0px', paddingBottom: '0px', border: 'none', width: '130%' }} >
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
            </select>
        );
    }
}
export default withRouter(DropdownLanguage);