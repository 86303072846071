import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import  DropdownLanguage  from './DropdownLanguage';

/*import './NavMenu.css';*/

 export class NavMenu extends Component {
    static displayName = NavMenu.name;


    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    

    render() {

        return (
            
            <>
                <div className="navbar-area header-three" id="navbar">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="/">
                                <img className="logo-light" src="../assets/img/ReviewtimesLogo.png" alt="logo" style={{ height: '31px', width: "153px" }} />
                            </a>
                            <button type="button" className="search-btn d-lg-none" data-bs-toggle="modal" data-bs-target="#searchModal">
                                <i className="flaticon-loupe"></i>
                            </button>
                            <a className="navbar-toggler" data-bs-toggle="offcanvas" href="#navbarOffcanvas" role="button" aria-controls="navbarOffcanvas">
                                <span className="burger-menu">
                                    <span className="top-bar"></span>
                                    <span className="middle-bar"></span>
                                    <span className="bottom-bar"></span>
                                </span>
                            </a>
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav mx-auto" style={{ paddingLeft: '0px'}} >

                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="dropdown-toggle nav-link" to="/business">Business</NavLink>
                                        <ul className="dropdown-menu">
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/business/finance">Finance</NavLink>
                                            </NavItem>
                                        </ul>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="dropdown-toggle nav-link" to="/lifestyle"> Lifestyle</NavLink>
                                        <ul className="dropdown-menu">
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/lifestyle/travel">Travel</NavLink>
                                            </NavItem>
                                        </ul>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="nav-link" to="/trending"> Trending</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="nav-link" to="/humor"> Humor</NavLink>
                                    </NavItem>

                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="dropdown-toggle nav-link" to="/reviews">Reviews</NavLink>
                                        <ul className="dropdown-menu">
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/reviews/web-series">Web Series</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/reviews/movies">Movies</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/reviews/ott-platform">Ott Platform</NavLink>
                                            </NavItem>
                                        </ul>
                                    </NavItem>

                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="nav-link" to="/health"> Health</NavLink>
                                    </NavItem>

                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="dropdown-toggle nav-link" to="/sports"> Sports</NavLink>
                                        <ul className="dropdown-menu">
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/sports/cricket">Cricket</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/sports/tennis">Tennis</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/sports/hockey">Hockey</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/sports/football">Football</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/sports/kabaddi">Kabaddi</NavLink>
                                            </NavItem>
                                        </ul>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="nav-link" to="/video"> Video</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <NavLink tag={Link} className="dropdown-toggle nav-link"> Others</NavLink>
                                        <ul className="dropdown-menu">
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/technology">Technology</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="nav-link" to="/ecoindia">Eco India</NavLink>
                                            </NavItem>
                                        </ul>
                                    </NavItem>
                                    <div style={{ color:'#e93314'}}>
                                        Language: <DropdownLanguage />
                                    </div>
                                </ul>
                                
                            </div>
                            
                        </nav>
                        

                    </div>
                    
                </div>

                <div className="responsive-navbar offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="navbarOffcanvas">
                    <div className="offcanvas-header">
                        <a href="/" className="logo d-inline-block">
                            <img className="logo-light" src="../assets/img/ReviewtimesLogo.png" alt="logo" />
                        </a>
                        <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="accordion" id="navbarAccordion">
                            <div className="accordion-item">
                                <button className="accordion-button collapsed active" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Business
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion2">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link active" to="/business">Business</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/business/finance">Finance</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapbaxour" aria-expanded="false" aria-controls="collapbaxour">
                                    Lifestyle
                                </button>
                                <div id="collapbaxour" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion45">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/lifestyle"> Lifestyle</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/lifestyle/travel">Travel</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Trending
                                </button>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion7">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/trending"> Trending</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Humor
                                </button>
                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion30">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/humor"> Humor</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    reviews
                                </button>
                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion11">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/reviews"> Reviews</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/reviews/web-series">Web Series</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/reviews/movies">movies</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/reviews/ott-platform">Ott Platform</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourth" aria-expanded="false" aria-controls="collapseFourth">
                                    Health
                                </button>
                                <div id="collapseFourth" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion111">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/health"> Health</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Sports
                                </button>
                                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion70">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports"> Sports</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports/cricket">Cricket</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports/tennis">Tennis</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports/hockey">Hockey</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports/football">Football</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/sports/kabaddi">Kabaddi</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Video
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion233">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/video"> Video</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Others
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#navbarAccordion">
                                    <div className="accordion-body">
                                        <div className="accordion" id="navbarAccordion342">
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link"> Others</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/technology">Technology</NavLink>
                                            </div>
                                            <div className="accordion-item">
                                                <NavLink tag={Link} className="accordion-link" to="/ecoindia">Eco India</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label for="cars">Choose a car:</label>
                            <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="opel">Opel</option>
                                <option value="audi">Audi</option>
                            </select>

                        </div>
                        <div className="offcanvas-contact-info">
                            <h4>Contact Info</h4>
                            <ul className="contact-info list-style">
                                <li>
                                    <i className="ri-map-pin-fill"></i>
                                    <p>28/A Street, Noida, India</p>
                                </li>
                                <li>
                                    <i className="ri-mail-fill"></i>
                                    <a href="https://templates.hibootstrap.com/cdn-cgi/l/email-protection#7d15181111123d1f1c0512531e1210"><span className="__cf_email__" data-cfemail="2e464b4242416e4c4f5641004d4143">[email&#160;protected]</span></a>
                                </li>
                                <li>
                                    <i className="ri-phone-fill"></i>
                                    <a href="tel:1800123456789">+1 --- --- --- ---</a>
                                </li>
                            </ul>
                            <ul className="social-profile list-style">
                                <li><a href="https://www.fb.com/" target="_blank"><i className="ri-facebook-fill"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i className="ri-linkedin-fill"></i></a></li>
                                <li><a href="https://www.twitter.com/" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                            </ul>
                        </div>
                        <div className="others-option d-flex d-lg-none align-items-center">
                            <div className="option-item">
                                <a className="btn-two">Sign In</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

//export default NavMenu;