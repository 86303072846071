import React from 'react'

export default function Footer() {

    return (

        <>
            <div class="container-fluid">
                <div class="footer-wrap">
                    <div class="row align-items-center">
                        <div class="col-lg-4">
                            <p class="copyright-text">Copyright © <span>reviewtimes</span> All Rights Reserved</p>
                        </div>
                        <div class="col-lg-4 text-center">
                            <ul class="social-profile list-style">
                                <li><a href="https://www.facebook.com/reviewtimes/" target="_blank"><i class="flaticon-facebook-1"></i></a></li>
                                <li><a href="https://x.com/reviewtimesin/" target="_blank"><i class="flaticon-twitter-1"></i></a></li>
                                <li><a href="https://www.instagram.com/reviewtimesindia/" target="_blank"><i class="flaticon-instagram-2"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/reviewtimes/" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <div class="footer-right">
                                <button class="subscribe-btn" data-bs-toggle="modal" data-bs-target="#newsletter-popup111">Become a subscriber<i class="flaticon-right-arrow"></i></button>
                                <p>Get all the latest posts delivered straight to your inbox.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <button type="button" id="backtotop" class="position-fixed text-center border-0 p-0">
                <i class="ri-arrow-up-line"></i>
            </button>


        </>

    )

}