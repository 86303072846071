import React, { useEffect, useState } from 'react'
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Category() {

    const [data, setdata] = useState([])
    const pathname = window.location.pathname
    const [currentUrl, setCurrentUrl] = React.useState(pathname)

    let [CategoryAtricle, SetCategoryAtricle] = useState("");
    let [FooterText, SetFooterText] = useState("");

    //languagedetector is middleware which help to find the current language and change it accordingly
    //i18n.use(languagedetector).use(initReactI18next)

    

    useEffect(() => {

        setCurrentUrl(pathname)
        //Get selected language from local storage
        var name = localStorage.getItem('language');
        
        var url = window.location.href.replace("https://www.reviewtimes.in/", '');
        SetCategoryAtricle(url);
        url = url.replace("/", "$");
        fetch('https://www.reviewtimes.in/WeatherForecast/Getbycotegory/' + url + "$" + name).then((response) => response.json()).then((result) => {
            console.log(result)
            if (result.length > 0) {
                document.title = result[0].pageTitle
                //("META")[3] means 3rd meta tag on page
                document.getElementsByTagName("META")[3].content = result[0].metaDecription;
                document.getElementsByTagName("META")[4].content = result[0].pageTitle;
                document.getElementsByTagName("META")[5].content = result[0].keyword;
                SetFooterText(result[0].footerText);
                setdata(result);
            }
            else {
                document.title = "";
                //("META")[3] means 3rd meta tag on page
                document.getElementsByTagName("META")[3].content = "";
                document.getElementsByTagName("META")[4].content = "";
                document.getElementsByTagName("META")[5].content = "";
                SetFooterText("");
                setdata(result);
            }
            
        })

    }, [pathname])


    return (

        <>
            <div class="breadcrumb-wrap">
                <div class="container">
                    <h2 class="breadcrumb-title">{CategoryAtricle.charAt(0).toUpperCase() + CategoryAtricle.slice(1) }</h2>
                </div>
            </div>
            <div class="sports-wrap ptb-100">
                <div class="container">
                    <div class="row gx-55 gx-5">
                        <div class="col-lg-8">
                            <div class="row justify-content-center">

                                {
                                    
                                    data.map((item) =>

                                                <div class="col-md-6">
                                                    <div class="news-card-thirteen">
                                                        <div class="news-card-img">
                                                            <img src={item.homePageImg} alt="Iamge" />
                                                            <a class="news-cat">Business</a>
                                                        </div>
                                                        <div class="news-card-info">
                                                            <h3><NavLink tag={Link} to={item.newsUrl}> {item.newsHeading} </NavLink></h3>
                                                            <ul class="news-metainfo list-style">
                                                                <li><i class="fi fi-rr-calendar-minus"></i>{new Date(item.newsCreateDate).toDateString()}</li>
                                                                <li><i class="fi fi-rr-clock-three"></i>Minutes to Read: 4 min</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        )
                                        
                                }
                                
                            </div>
                            
                        </div>
                        <div class="col-lg-4">
                            <div class="sidebar">
                                <div class="sidebar-widget-two">
                                    <form action="#" class="search-box-widget">
                                        <input type="search" placeholder="Search" />
                                        <button type="submit">
                                            <i class="fi fi-rr-search"></i>
                                        </button>
                                    </form>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Categories</h3>
                                    <ul class="category-widget list-style">
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Celebration <span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Culture<span>(3)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Fashion<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Inspiration<span>(8)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Lifestyle<span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Politics<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Trending<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Recent Posts</h3>
                                    <div class="pp-post-wrap">
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-4.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Bernie Nonummy Pelopai Iatis Eum Litora</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-5.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 23, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-6.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 14, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-7.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">First Prototype Flight Using Kinetic Launch System</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="latest-news-three pb-40">
                <div class="container">
                    <hr class="mb-40" />
                    <div class="row gx-55 gx-5">
                        <div class="col-xl-12">
                            <p style={{ textAlign: 'justify' }}>{FooterText } </p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )

}